import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import useFetchHook from "../useFetchHook/useFetchHook.js";
import { saveAs } from 'file-saver'
import LoadingCo from "../components/LoadingCo/LoadingCo.js";
function LinkesPage() {
  const { data, loading, error } = useFetchHook(
    "https://old-baghdad.mosulcode.com/links"
  );
  const downloadImage = (url) => {
    saveAs(url) // Put your image url here.
  }

  if (loading) return <LoadingCo state="Loading... "/>;
  if (error) return <LoadingCo state="Error... " />;
  return (
      <Container>
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">titel</TableCell>
            <TableCell align="center">path</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.title}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
             
              <TableCell align="left">{row.links_title}</TableCell>
              <TableCell onClick={()=>{downloadImage(row.links_path)}} align="left"><a href={row.links_path} >{row.links_path}</a></TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </Container>
  
  );
}

export default LinkesPage;
