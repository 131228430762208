import React from "react";
import GlobalStyle from "./globalStyles";
import { Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";

//Pages
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import LinkesPage from "./pages/LinkesPage";
import ImageSlider from "./pages/ImageSlider";
import InfintyPage from "./pages/InfintyPage";
import PddfAndImagePage from "./pages/PddfAndImagePage";
import Footer from "./components/Footer/Footer";
import { useTranslation } from "react-i18next";
import "./app.css";

function App() {
  const { t } = useTranslation();
  // useEffect(() => {
  // 	document.body.dir = t("dir");
  // },[]);
  return (
    <div className={t("clas")}>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="LinkesPage" element={<LinkesPage />} />
        <Route path="ContactUs" element={<ContactUs />} />
        <Route path="page/:id" element={<InfintyPage />} />
        <Route path="map_image/:id" element={<ImageSlider />} />
        <Route path="PddfAndImagePage/:id" element={<PddfAndImagePage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
