import { useEffect, useState } from "react";
import axios from "axios";

function useFetchHook(url, key) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  useEffect(() => {
    setLoading(true);
    axios.defaults.headers.common = {
        "trn-api-key": "123123",
        "content-type": "application/json"
      };
    axios
      .get(url)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });

    const refetch = () => {
      setLoading(true);
      axios
        .get(url)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    };
  }, [url]);
  return { data, loading, error };
}
export default useFetchHook;
