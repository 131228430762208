import React, { useEffect } from "react";
import { Container, Section4 } from "../../globalStyles";
import { useTranslation } from "react-i18next";
import all1 from "./all1.png";
import anotherLogo from "./anotherLogo.jpg";
import kohrwLogo from "./kohrwLogo.png";
import therdLogo from "./therdLogo.png";
import Grid from "@mui/material/Grid";
import {
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  ContentButton,
  Subtitle,
  ImgWrapper,
  Img,
  ContentColumn,
} from "./LogosAndTextStyles.js";

import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

export const LogosAndText = ({
  primary,
  topLine,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  inverse,
  reverse,
}) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();
  const { t } = useTranslation();
  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  return (
    <Section4 inverse={true} ref={ref}>
      <ContentRow reverse={true}>
        <ContentColumn>
          <Grid container xs={12}>
            <Grid sx={{ justifyContent: "center" }} item container>
              <ImgWrapper>
                <Img src={all1} alt={alt} transition={{ duration: 0.5 }} />
              </ImgWrapper>
              
            </Grid>
            <TextWrapper>
              <Heading
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
                inverse={true}
              >
                The Social Cohesion Initiative is supported by the United
                Nations Development Program in Iraq in partnership with the
                Kurdistan Human Rights Monitoring Organization, and funded by
                the Governments of Denmark and Germany.
              </Heading>
            </TextWrapper>
          </Grid>
        </ContentColumn>

        <ContentColumn
          initial={initial}
          transition={{ delay: 0.5, duration: 0.6 }}
          animate={animation}
        >
          <Grid alignItems="flex-end" item>
            <Img
              src="https://media.istockphoto.com/illustrations/marketplace-with-grand-mosque-ahmetkhiaia-in-baghdad-1861-illustration-id1304410330?k=20&m=1304410330&s=612x612&w=0&h=yfqQy06E05HoEIGnq_KT3PTl8QYEV0LT30SH9goEFB0="
              alt={alt}
              transition={{ duration: 0.5 }}
            />
          </Grid>
        </ContentColumn>
      </ContentRow>
    </Section4>
  );
};
