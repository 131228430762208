import React from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
function LoadingCo(props) {
  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      container
      sx={{ height: "65vh" }}
    >
      <grid item xs={4}>
        <h1>{props.state}</h1>
      </grid>
      <grid item xs={4}>
        <CircularProgress color="inherit" />
      </grid>
    </Grid>
  );
}

export default LoadingCo;
