import React from "react";
import {
  FooterLogo,
  SocialIcon,
  FooterRights,
  FooterSocialIcon,
  FooterWrapper,
  FooterAddress,
  FooterColumn,
  FooterGrid,
} from "./FooterStyles";
import { footerSocialData } from "../../data/FooterData";
import { Row, Section3 } from "../../globalStyles";
import logo from "../Navbar/logo.png";
import { useTranslation } from "react-i18next";

function Footer() {
  const {t} = useTranslation();
  const d = new Date();
  let year = d.getFullYear();
  return (
    
    <Section3 margin-bottom="0" padding="4rem 0 2rem 0">
      <FooterWrapper>
        <FooterGrid justify="space-between">
          <FooterLogo to="/">
            <SocialIcon src={logo} />
            Baghdad Heritage
          </FooterLogo>
          <FooterColumn id="footerLogo">
            <FooterAddress>{t('followUs')}</FooterAddress>

            <Row align="center" margin="auto  0 0 0" gap="1rem">
              {footerSocialData.map((social, index) => (
                <FooterSocialIcon
                  key={index}
                  href="/"
                  target="_blank"
                  aria-label={social.name}
                >
                  {social.icon}
                </FooterSocialIcon>
              ))}
            </Row>
          </FooterColumn>
        </FooterGrid>
        <FooterRights>{"Old Baghdad "+year }</FooterRights>
      </FooterWrapper>
    </Section3>
  );
}

export default Footer;
