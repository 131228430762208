import React from 'react'
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { saveAs } from 'file-saver'

function ImageCard(props) {
  const downloadImage = (url) => {
    saveAs(url) // Put your image url here.
  }
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="220"
        image={props.item.map_image_path}
        alt="green iguana"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.item.map_image_title}
        </Typography>
        <CardActions>
        <Button onClick={()=>{downloadImage(props.item.map_image_path)}} size="small">Doawnload</Button>
      </CardActions>
      </CardContent>
     
    </Card>
  )
}

export default ImageCard