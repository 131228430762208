import React from "react";
import ImageCard from "../components/ImageCard/ImageCard";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import useFetchHook from "../useFetchHook/useFetchHook.js";
import LoadingCo from "../components/LoadingCo/LoadingCo.js";
function ImageSlider() {
  const { data, loading, error } = useFetchHook(
    `https://old-baghdad.mosulcode.com${window.location.pathname}`
  );
  if (loading) return <LoadingCo state="Loading... " />;
  if (error) return <LoadingCo state="Error... "/>;
  return (
    <Container sx={{ marginTop: "22px", marginBottom: "22px" }}>
      <Grid container justifyContent="center" spacing={3}>
        {data?.map((item) => (
          <Grid item xs={12} sm={3}>
            <ImageCard item={item}></ImageCard>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default ImageSlider;
