import React from "react";
import Grid from "@mui/material/Grid";
import Infinty from "../components/Infinty/Infinty";
import Hero from "../components/Hero/Hero";
import useFetchHook from "../useFetchHook/useFetchHook";
import LoadingCo from "../components/LoadingCo/LoadingCo";
function InfintyPage() {
  const { data, loading, error } = useFetchHook(
    `https://old-baghdad.mosulcode.com/header/all/body/${
      window.location.pathname.split("/")[2]
    }`
  );
  if (loading) return <LoadingCo state="Loading... " />;
  if (data.length <= 1) return <LoadingCo state="Loading... " />;
  if (error) return <LoadingCo state="Error... " />;
  if (data.sub_body.length <= 1)
    return (
      <div>
        <Grid
          justifyContent="center"
          alignItems="center"
          container
          sx={{ height: "65vh" }}
        >
          <grid item xs={8}>
            <h1>Under construction...</h1>
          </grid>
        </Grid>
      </div>
    );
  return (
    <div>
      {data.body[0]?.header_title_en.length <= 0 ? null : (
        <Hero
          title={data.body[0]?.header_title_en}
          subTitle={data.body[0]?.header_sub_title_en}
          image={data.body[0]?.header_image}
        />
      )}

      {console.log(data)}
      <Infinty item={data}></Infinty>
    </div>
  );
}

export default InfintyPage;
