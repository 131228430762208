import React from "react";
import { Section } from "../../globalStyles";
import "../../../src/app.css";
import Grid from "@mui/material/Grid";
import { ContentRow,TopLine, TextWrapper, Heading, Img } from "./MapMarkerStyles.js";
// import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import oldPlase from "./oldPlase.png";
import useFetchHook from "../../useFetchHook/useFetchHook.js";
import { useNavigate } from "react-router-dom";


export const MapMarker = ({ inverse, reverse }) => {
  let navigate = useNavigate();
  const { data, loading, error } = useFetchHook(
    "https://old-baghdad.mosulcode.com/map"
  );
  const defaultProps = {
    center: {
      lat: 33.38263412714732,
      lng: 44.372931493508005,
    },
    zoom: 14,
    mapTypeControl: true,
  };

  const getMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: false,
      styles: [
        {
          featureType: "poi.business",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,
      minZoom: 11,
      maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.SATELLITE,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },

      zoomControl: true,
      clickableIcons: false,
    };
  };
  const handleClick = (ind) => {
    navigate("/map_image/"+ind);
  };
  return (
    <Section inverse={true}>
      <ContentRow reverse={reverse}>
        <TextWrapper>
          <Heading inverse={true}>
            Find the archive of your favorite historical building
          </Heading>
        </TextWrapper>
        <div style={{ height: "60vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBiKB5wE3ri5wF2ra5mpM4D9iAmz3yIMJo",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
          >
            {data?.map((loc) => (
               <Grid onClick={()=>{handleClick(loc.map_no)}} container xs={12} direction="column" key={loc.map_lat} lat={loc.map_lat} lng={loc.map_long}>
               <Grid item>
                 <Img alt="image" src={oldPlase} />
               </Grid>
              <Grid item > 
              <TextWrapper>
                     <TopLine inverse={true}>
                       {loc.map_title_en}
                     </TopLine>
                   </TextWrapper>
              </Grid>
             </Grid>
             
            ))}
          </GoogleMapReact>
        </div>
      </ContentRow>
    </Section>
  );
};
