import React,{useState} from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

function ListCo(props) {
  const [open, setOpen] = useState(false);
  let navigate = useNavigate();
  const handleClick = () => {
    setOpen(!open);
  };
  const handleGoTo = (pathname) => {
    navigate(pathname);
    props.stateChanger(false);
    window.location.reload(false);
  };
  return (
    <div>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "",marginLeft:"auto",marginRight:"auto" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
         
        <ListItemButton onClick={()=>{
          props.data.dropdown[props.page.app_bar_no].length <= 1?handleGoTo("/page/" + props.data.dropdown[props.page.app_bar_no][0].dropdown_name_unique):handleClick();
        }}>
          <ListItemText
            primary={
              props.data.dropdown[props.page.app_bar_no].length <= 1
                ? props.data.dropdown[props.page.app_bar_no][0].dropdown_name_en
                : props.page.app_bar_name_en
            }
          />
          {props.data.dropdown[props.page.app_bar_no].length <= 1?null:open ? <ExpandLess /> : <ExpandMore />}
         {console.log(props.data.dropdown[props.page.app_bar_no].length <= 1)}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.data.dropdown[props.page.app_bar_no].length <= 1
              ? null
              : props.data.dropdown[props.page.app_bar_no]?.map((dropItem) => (
                  <ListItemButton sx={{ pl: 4 }}>
                    {/* <a href={"/page/" + dropItem.dropdown_name_unique}> */}
                      <ListItemText onClick={()=>handleGoTo("/page/" + dropItem.dropdown_name_unique)} primary={dropItem.dropdown_name_en} />
                    {/* </a> */}
                  </ListItemButton>
                ))}
          </List>
        </Collapse>
      </List>
      {/* <li className="dropdown">
        <a
          href={
            "/page/" +
            props.data.dropdown[props.page.app_bar_no][0].dropdown_name_unique
          }
          className="dropbtn"
        >
          {props.data.dropdown[props.page.app_bar_no].length <= 1
            ? props.data.dropdown[props.page.app_bar_no][0].dropdown_name_en
            : props.page.app_bar_name_en}
        </a>
        <div className="dropdown-content">
          {props.data.dropdown[props.page.app_bar_no].length <= 1
            ? null
            : props.data.dropdown[props.page.app_bar_no]?.map((dropItem) => (
                <a href={"/page/" + dropItem.dropdown_name_unique}>
                  {dropItem.dropdown_name_en}
                </a>
              ))}
        </div>
      </li> */}
    </div>
  );
}

export default ListCo;
