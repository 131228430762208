import React from "react";
import {ContentOfContactUs} from "../components/ContentOfContactUs/ContentOfContactUs"
function ContactUs() {
  
  return (
   <ContentOfContactUs></ContentOfContactUs>
  );
}

export default ContactUs;
