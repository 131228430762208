import React from "react";
import CardComp from "../components/CardComp/CardComp";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import useFetchHook from "../useFetchHook/useFetchHook";
import LoadingCo from "../components/LoadingCo/LoadingCo.js";
function PddfAndImagePage() {
  const { data, loading, error } = useFetchHook(
    `https://old-baghdad.mosulcode.com/pdf_image/${
      window.location.pathname.split("/")[2]
    }`
  );
  if (loading) return <LoadingCo state="Loading... " />;
  if (error) return <LoadingCo state="Error... " />;
  return (
    <Container sx={{ marginTop: "22px", marginBottom: "22px" }}>
      <Grid container justifyContent="center" spacing={2}>
        {data?.map((item) => (
            <Grid item xs={12} sm={3}>
          <CardComp item={item}></CardComp>
        </Grid>
        )) }
      
      </Grid>
    </Container>
  );
}

export default PddfAndImagePage;
