import React, { useEffect,useState } from "react";
import { Section } from "../../globalStyles";
import { useTranslation } from "react-i18next";
import {
  ContentRow,
  TextWrapper,
  Heading,
  ContentButton,
  Subtitle,
  ImgWrapper,
  Img,
  ContentColumn,
} from "./ContentOfContactUsStyles.js";
import CircularProgress from '@mui/material/CircularProgress';
import Send from "./Send.svg";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";
import { styled } from "@mui/material/styles";
import { Input } from "@mui/material";
export const ContentOfContactUs = ({ primary, alt, inverse, reverse }) => {
  const Input = styled("input")({
    display: "none",
  });
  const [selectedFile, setSelectedFile] = useState("");
  const [spin,setSpin]=useState(false);
  const handleSubmit = (event) => {
    setSpin(true);
    event.preventDefault();
    //console.log(selectedFile);
    const formData = new FormData();
    formData.append("file", selectedFile);
    fetch("https://old-baghdad.mosulcode.com/image/upload/pages", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())

      .then((result) => {
        alert(result.message);
        setSpin(false);
      })

      .catch((error) => {
        alert("Error");
      });
    setSelectedFile("");
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();
  const { t } = useTranslation();
  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);
  const hiddenFileInput = React.useRef(null);
  return (
    <Section inverse={inverse} ref={ref}>
      <ContentRow reverse={reverse}>
        <ContentColumn>
          <TextWrapper paddingTop={true}>
            <Heading
              //initial={initial}
              //transition={{ delay: 0.5, duration: 0.6 }}
              //animate={animation}
              inverse={inverse}
            >
              Contact us by email
            </Heading>

            <ContentButton
              //initial={initial}
              //transition={{ delay: 1, duration: 0.6 }}
             // animate={animation}
              inverse={inverse}
              primary={primary}
              onClick={() => {
                window.location = "mailto:baghdadheritage@baghdadheritage.org";
              }}
            >
              send us an email
            </ContentButton>
          </TextWrapper>
          <TextWrapper paddingTop={false}>
            <Heading
             // initial={initial}
             // transition={{ delay: 0.5, duration: 0.6 }}
             // animate={animation}
              inverse={inverse}
            >
              Be a contributor
            </Heading>
            <Subtitle
              //initial={initial}
             // transition={{ delay: 0.7, duration: 0.6 }}
             // animate={animation}
              inverse={inverse}
            >
              You can contribute with us to the dissemination of culture by
              sending photos or files related to the heritage of Baghdad
            </Subtitle>

            <label htmlFor="contained-button-file">
              <input
                id="contained-button-file"
                type="file"
                ref={hiddenFileInput}
                style={{ display: "none" }}
                onChange={handleFileSelect}
              />
              {spin? <CircularProgress />:selectedFile === "" ? (
                <ContentButton
                  onClick={() => {
                    hiddenFileInput.current.click();
                  }}
                  inverse={inverse}
                  primary={primary}
                >
                  Choose your files
                </ContentButton>
              ) : null}
             
            </label>
            
            {selectedFile === "" ? null : (
              <ContentButton
                //initial={initial}
                onClick={handleSubmit}
                //transition={{ delay: 1, duration: 0.6 }}
                //animate={animation}
                inverse={true}
                primary={primary}
              >
                Uplod your file
              </ContentButton>
            )}
          </TextWrapper>
        </ContentColumn>
        <ContentColumn
          //initial={initial}
          //transition={{ delay: 0.5, duration: 0.6 }}
          //animate={animation}
        >
          <ImgWrapper>
            <Img
              src={Send}
              alt={alt}
              whileHover={{ rotate: 2, scale: 1.02 }}
              transition={{ duration: 0.5 }}
            />
          </ImgWrapper>
        </ContentColumn>
      </ContentRow>
    </Section>
  );
};
