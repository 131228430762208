export const heroOne = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Founded in 2015',
	},
	headline: "Save Baghdad Heritage & Stop the destruction",
	description: 'The beginning at 2005 was with two creative and self-mo-tivated engineers , our todays\' leaders .The dream is to offer IT based smart solutions merged with loT concepts and mobile applications.',
	buttonLabel: 'Read More',
	imgStart: 'start',
	img: './assets/svg/first.jpg',
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Ready to take your project',
	},
	headline: 'Contact Us',
	description: 'Our clients have had the best experience working with us',
	buttonLabel: 'Send',

	linkTo: '/more',
	imgStart: 'start',
	img: './assets/svg/Contact-Us.png',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'for the best future',
	},
	headline: 'Preserve the Heritage values of Baghdad traditional houses',
	description:
		'Our target is to provide our Smart Solutions as well as advanced technology concepts to all vital fields like : military works , civil governmental institutes and even personal uses.',
	buttonLabel: 'Read More',

	linkTo: '/download',
	imgStart: '',
	img: './assets/svg/second.jpg',
	start: 'true',
};
