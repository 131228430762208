import React from "react";
import Grid from "@mui/material/Grid";
import {
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  ImgWrapper,
  Img,
} from "./InfintyStyles.js";

function Infinty(props) {
  return (
    <Grid justifyContent="center" container>
      {props.item.body?.map((mainitem) => (
        <>
          <Grid item xs={8}>
            {mainitem.body_title_en.length <= 0 ? null : (
              <TextWrapper>
                <Heading inverse={true}>{mainitem.body_title_en}</Heading>
              </TextWrapper>
            )}
          </Grid>

          {props.item.sub_body[mainitem.body_no]?.map((subitem) => (
            <>
              <Grid item xs={11} sm={8}>
                {subitem.sub_body_text_en.length <= 0 ? null : (
                  <TextWrapper>
                    <Subtitle>{subitem.sub_body_text_en}</Subtitle>{" "}
                  </TextWrapper>
                )}
              </Grid>

              <Grid item xs={11} sm={8}>
                {subitem.sub_body_description_en.length <= 0 ? null : (
                  <TextWrapper>
                    <TopLine> {subitem.sub_body_description_en}</TopLine>{" "}
                  </TextWrapper>
                )}
              </Grid>

              <Grid justifyContent="center" item xs={8}>
                {subitem.sub_body_path.length <= 0 ? null : (
                  <ImgWrapper>
                    <Img src={subitem.sub_body_path}></Img>
                  </ImgWrapper>
                )}
              </Grid>
            </>
          ))}
        </>
      ))}
    </Grid>
  );
}

export default Infinty;
