import React, { useEffect } from "react";
import { Section } from "../../globalStyles";
import { useTranslation } from "react-i18next";
import {
  ContentRow,
  TextWrapper,
  TopLine,
  Heading,
  ContentButton,
  Subtitle,
  ImgWrapper,
  Img,
  ContentColumn,
} from "./ContentStyles.js";
import { useInView } from "react-intersection-observer";
import { useAnimation } from "framer-motion";

export const Content = ({
  primary,
  headline,
  alt,
  img,
  inverse,
  reverse,
}) => {
  const initial = { opacity: 0, y: 30 };
  const animation = useAnimation();
  const { t } = useTranslation();
  const { ref, inView } = useInView({ threshold: 0.2 });

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      });
    }
  }, [inView, animation]);

  return (
    <Section inverse={inverse} ref={ref}>
      <ContentRow reverse={reverse}>
        <ContentColumn>
          <TextWrapper>
            {/* <TopLine
              initial={initial}
              transition={{ delay: 0.3, duration: 0.6 }}
              animate={animation}
            >
              1980
            </TopLine> */}
            <Heading
              initial={initial}
              transition={{ delay: 0.5, duration: 0.6 }}
              animate={animation}
              inverse={inverse}
            >
              {headline}
            </Heading>
            {/* <Subtitle
              initial={initial}
              transition={{ delay: 0.7, duration: 0.6 }}
              animate={animation}
              inverse={inverse}
            >
              It was established more than twenty years ago and it was one of
              the most important places where people spend their prayers
            </Subtitle> */}
            {/* <ContentButton
              initial={initial}
              transition={{ delay: 1, duration: 0.6 }}
              animate={animation}
              inverse={inverse}
              primary={primary}
            >
              {buttonLabel}
            </ContentButton> */}
          </TextWrapper>
        </ContentColumn>
        <ContentColumn
          initial={initial}
          transition={{ delay: 0.5, duration: 0.6 }}
          animate={animation}
        >
          <ImgWrapper>
            <Img
              src={img}
              alt={alt}
              whileHover={{ rotate: 2, scale: 1.02 }}
              transition={{ duration: 0.5 }}
            />
          </ImgWrapper>
        </ContentColumn>
      </ContentRow>
    </Section>
  );
};
