import React, { useState } from "react";
import {
  AppBar,
  Box,
  Skeleton,
  Toolbar,
  Typography,
  Container,
  Stack,
  List,
  ListItemButton,
  ListItemText,
  Collapse,
  Drawer,
  IconButton,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import useFetchHook from "../../useFetchHook/useFetchHook.js";
import { FaTimes } from "react-icons/fa";
import { Menu } from "./NavbarStyles.js";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import ListCo from "./ListCo";
import MenuIcon from "@mui/icons-material/Menu";

const Navbar = () => {
  const { data, loading, error } = useFetchHook(
    "https://old-baghdad.mosulcode.com/app_bar/navbar/all"
  );
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#212026",
      },
    },
  });
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  //const [open, setOpen] = React.useState(true);

  const [open, setOpen] = useState(false);

  const handleClickD = () => {
    setOpen(!open);
  };

  const handleClick = () => {
    setShow(!show);
  };
  const handleGoTo = (pathname) => {
    navigate(pathname);
    handleClick();
  };
  const rep = [1, 2, 3, 4, 5, 6, 7, 8];

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerToggle}
        sx={{ ml: "80%" }}
      >
        <FaTimes />
      </IconButton>
      <List>
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            onClick={() => {
              handleGoTo("/");
              handleDrawerToggle();
            }}
          >
            <ListItemText primary="Home" />
          </ListItemButton>
        </List>
        {data.app_bar?.map((page) => (
          <ListCo stateChanger={setMobileOpen} data={data} page={page}></ListCo>
        ))}
        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={handleClickD}>
            <ListItemText primary="Documentation" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                onClick={() => {
                  handleGoTo("/PddfAndImagePage/1");
                  handleDrawerToggle();
                }}
                sx={{ pl: 4 }}
              >
                <ListItemText primary="Digitized archive for heritage conservation" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleGoTo("/PddfAndImagePage/2");
                  handleDrawerToggle();
                }}
                sx={{ pl: 4 }}
              >
                <ListItemText primary="The history of Baghdad books" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleGoTo("/PddfAndImagePage/3");
                  handleDrawerToggle();
                }}
                sx={{ pl: 4 }}
              >
                <ListItemText primary="Articles and scholars" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleGoTo("/PddfAndImagePage/3");
                  handleDrawerToggle();
                }}
                sx={{ pl: 4 }}
              >
                <ListItemText primary="Baghdad old city videos" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleGoTo("/PddfAndImagePage/3");
                  handleDrawerToggle();
                }}
                sx={{ pl: 4 }}
              >
                <ListItemText primary="Conservation projects" />
              </ListItemButton>
              <ListItemButton
                onClick={() => {
                  handleGoTo("/PddfAndImagePage/3");
                  handleDrawerToggle();
                }}
                sx={{ pl: 4 }}
              >
                <ListItemText primary="Related Websites" />
              </ListItemButton>
            </List>
          </Collapse>
        </List>

        <List
          sx={{
            width: "100%",
            maxWidth: 360,
            bgcolor: "",
          }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            onClick={() => {
              handleGoTo("/ContactUs");
              handleDrawerToggle();
            }}
          >
            <ListItemText primary="Contact us" />
          </ListItemButton>
        </List>
      </List>
    </div>
  );

  if (error) return <div>Error...</div>;
  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="sticky" color="primary" enableColorOnDark>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: "flex" }}>
              <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                  display: { xs: "block", sm: "none" },
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: 240,
                  },
                }}
              >
                {drawer}
              </Drawer>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {loading ? (
                <Stack direction="row" spacing={6}>
                  {rep.map((item) => (
                    <Typography key={item} component="div" variant="h3">
                      <Skeleton width={100} />
                    </Typography>
                  ))}
                </Stack>
              ) : (
                <Menu>
                  <li className="dropdown">
                    <a href="/" className="dropbtn">
                      Home
                    </a>
                  </li>
                  {data.app_bar?.map((page) => (
                    <li className="dropdown">
                      <a
                        href={
                          "/page/" +
                          data.dropdown[page.app_bar_no][0].dropdown_name_unique
                        }
                        className="dropbtn"
                      >
                        {data.dropdown[page.app_bar_no].length <= 1
                          ? data.dropdown[page.app_bar_no][0].dropdown_name_en
                          : page.app_bar_name_en}
                      </a>
                      <div className="dropdown-content">
                        {data.dropdown[page.app_bar_no].length <= 1
                          ? null
                          : data.dropdown[page.app_bar_no]?.map((dropItem) => (
                              <a
                                href={"/page/" + dropItem.dropdown_name_unique}
                              >
                                {dropItem.dropdown_name_en}
                              </a>
                            ))}
                      </div>
                    </li>
                  ))}

                  <li className="dropdown">
                    <a href="/PddfAndImagePage/1" className="dropbtn">
                      Documentation
                    </a>
                    <div className="dropdown-content">
                      <a href="/PddfAndImagePage/1">
                        Digitized archive for heritage conservation
                      </a>
                      <a href="/PddfAndImagePage/3">
                        The history of Baghdad books
                      </a>
                      <a href="/PddfAndImagePage/2">Articles and scholars</a>
                      <a href="/PddfAndImagePage/4">Baghdad old city videos</a>
                      <a href="/PddfAndImagePage/5">Conservation projects</a>
                      <a href="/PddfAndImagePage/6">Related Websites</a>
                    </div>
                  </li>

                  <li className="dropdown">
                    <a href="/ContactUs" className="dropbtn">
                      Contact us
                    </a>
                  </li>
                </Menu>
              )}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export default Navbar;
