import React from "react";
import { Button, Container2, MainHeading } from "../../globalStyles";
import { HeroImage, HeroSection, HeroText, ButtonWrapper } from "./HeroStyles";
import { useTranslation } from "react-i18next";
import "../../../src/app.css";
const Hero = (props) => {
  const { t } = useTranslation();
  const looding = () => <div>loading...</div>;
  return (
    <HeroSection>
      <HeroImage src={props.image} />

      <Container2>
        <MainHeading>{props.title}</MainHeading>
        <HeroText>{props.subTitle}</HeroText>
        <ButtonWrapper>
          {/* <Link to="signup">
            <Button>Get Started</Button>
          </Link> */}
          {/* <Button>Get Started</Button> */}
        </ButtonWrapper>
      </Container2>
    </HeroSection>
  );
};

export default Hero;
