import React from "react";
import { Content } from "../components/Content/Content";
import { LogosAndText } from "../components/LogosAndText/LogosAndText";
import { MapMarker } from "../components/MapMarker/MapMarker";
import FeaturesCarousel from "../components/FeaturesCarousel/FeaturesCarousel";
import Hero from "../components/Hero/Hero";
import { heroOne, heroTwo, heroThree } from "../data/HeroData";
import { heroOneAR, heroTwoAR, heroThreeAR } from "../data/HeroDataAR";
// Hero Feature Content Carousel

const Home = () => {
  const currentLanguagesCode = localStorage.getItem("i18nextLng") || "en ";
  return (
    <div>
      <LogosAndText {...heroOne} />
      <Hero
        title="Baghdad Heritage"
        subTitle="History and Civilization"
        image="./assets/svg/third.jpg"
      />

      <FeaturesCarousel />

      <MapMarker {...heroTwo} />

      <Content {...heroOne} />

      <Content {...heroThree} />
    </div>
  );
};

export default Home;
