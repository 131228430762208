import React, { useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Row, Heading, Section } from "../../globalStyles";
import { useTranslation } from "react-i18next";
import "../../../src/app.css";
import useFetchHook from "../../useFetchHook/useFetchHook";

const CarouselComponent = () => {
  const { t } = useTranslation();
  const currentLanguagesCode = localStorage.getItem("i18nextLng") || "en ";

  const { data, loading, error } = useFetchHook(
    "https://old-baghdad.mosulcode.com/image_slider"
  );

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  if(loading) return <div>Loading...</div>
  if(error) return <div>Error...</div>
  return (
    <Section maxWidth="100%" padding="10px 0px 0px 0px" inverse>
      <Row justify="space-between" margin="1rem" wrap="wrap">
        <Heading width="auto" inverse>
          Latest achievements
        </Heading>
      </Row>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={false}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={2000}
        transitionDuration={1000}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-30-px"
      >
       
        {data?.map((images) => {
          return (
            <img
            key={images.image_slider_no}
              style={{ width: "100%", height: "100%", padding: "30px" }}
              src={images.image_slider_path}
              alt="carousel"
            >

           
            </img>
          );
        })}
      </Carousel>
    </Section>
  );
};

export default CarouselComponent;
