import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { saveAs } from 'file-saver'

function CardComp(props) {
  const downloadImage = (url) => {
    saveAs(url) // Put your image url here.
  }
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        component="img"
        height="220"
        image={props.item.pdf_image_interface.length<=0?"https://play-lh.googleusercontent.com/BkRfMfIRPR9hUnmIYGDgHHKjow-g18-ouP6B2ko__VnyUHSi1spcc78UtZ4sVUtBH4g":props.item.pdf_image_interface}
        alt="pdf image"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.item.pdf_image_title_en}
        </Typography>
        <Typography variant="body2" color="text.secondary">
         {props.item.pdf_image_sub_title_en}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={()=>{downloadImage(props.item.pdf_image_path)}} size="small">Doawnload</Button>
      </CardActions>
    </Card>
  );
}

export default CardComp;
